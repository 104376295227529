import React from 'react';

import SEO from '../components/seo';
import Layout from '../components/layout';
import CourseVocation from '../components/course/courseVocation';

const Doulos = () => {
  return (
    <>
      <SEO title="Vocation" />
      <Layout>
        <CourseVocation />
      </Layout>
    </>
  );
};

export default Doulos;
