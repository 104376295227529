import React from 'react';
import { Row, Col } from 'antd';

import Styles from './course-ind.module.css';
import HeroVocation from '../image-components/hero/heroVocation';
import TabAbout from '../image-components/tab/tabAbout';
import TabOverview from '../image-components/tab/tabOverview';
import TabGuide from '../image-components/tab/tabGuide';

import GuideImg from '../../images/guide-vocation.png';

const CourseVocation = () => {
  return (
    <>
      <HeroVocation />
      <Row className={Styles.row}>
        <Col className={Styles.col_left} sm={24} md={16}>
          <div className={Styles.heading}>VOCATION ADVANCE</div>
          <div className={Styles.body}>
            <div className={Styles.subtitle}>
              <div>Loving Our Vocations via the Love of God.</div>
              <p>
                Ekko’s Vocation Advance is centered on the unearthing of each
                person’s unique vocational credo and purpose in life, as God
                intended it. Each of us has been given a specific role that is
                shaped by our pains and passions throughout our life. God uses
                all experiences, good and bad, to begin forming a unique
                vocation that can be used to bless others and to bring renewal
                to the world in a specific way. It is in the uncovering of our
                vocations that we find our true purposes and identity in life
                revealed.
              </p>
            </div>
          </div>
          <div className={Styles.body}>
            <div className={Styles.quote}>
              "What else is all our work to God—whether in the fields, in the
              garden, in the city, in the house, in war, or in government—but
              just such a child’s performance, by which He wants to give His
              gifts in the fields, at home, and everywhere else? These are the
              masks of God, behind which He wants to remain concealed and do all
              things."
            </div>
            <div className={Styles.author}>MARTIN LUTHER</div>
          </div>
        </Col>
        <Col className={Styles.col_right} sm={24} md={8}>
          <TabAbout>
            <div className={Styles.modal_title}>ABOUT</div>
            <div className={Styles.modal_body}>
              <h2 className={Styles.modal_subtitle}>The Learning Outcomes</h2>
              <p className={Styles.subheading}>Advance participants will:</p>
              <ol>
                <li>
                  Participants will be able to uncover and verbalize their
                  unique vocational credo and God-given purpose in life.
                </li>
                <li>
                  Participants will be able to apply their unique credos to
                  their current settings in life.
                </li>
                <li>
                  Participants should be able to understand the broader strokes
                  of their vocational credo in their life, as well as imagine
                  new possibilities for how the credo can be expressed in the
                  future.
                </li>
              </ol>
              <h2 className={Styles.modal_subtitle}>The Big Idea</h2>
              <p className={Styles.subheading}>Cohort Based</p>
              <p>
                Ekko's Vocation Advance organizes participants to journey
                together through the entire program. In the duration of six
                weeks, participants’ primary learning and practice come from
                their willingness to be open and honest about their pains and
                passions. In so doing, they will uncover God’s intent and
                careful design in their lives.
              </p>
              <p className={Styles.subheading}>Advisor-Directed</p>
              <p>
                Vocation Advance is paired with a guide (Deborah Loyd) who will
                facilitate the weekly meetings.
              </p>
              <p className={Styles.subheading}>Online & Hybrid</p>
              <p>
                Vocation Advance engages in a variety of venues that are
                EPIC—experiential, participatory, image-driven and connective.
                It does not encourage simply downloading information; instead,
                participants and advisors value relationship, the dynamic
                interaction, and experimentation, which will enrich and deepen
                its outcome. The format comprise of weekly discussions online
                and weekly face-to-face meetings with group members.
              </p>
            </div>
          </TabAbout>
          <TabOverview>
            <div className={Styles.modal_title}>OVERVIEW</div>
            <div className={Styles.modal_body}>
              <div className={Styles.modal_paragraph}>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Vocation/Vocation%20Advance%20Syllabus%20S2021.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className={Styles.btn_syllabus}>Syllabus</button>
                </a>
                <div className={Styles.modal_subtitle}>Week 1</div>
                <div className={Styles.subheading}>
                  <ul>
                    <li>
                      Hour One: Introductions, syllabus review, critical
                      thinking and spiritual formation and the role they play in
                      vocational discernment
                    </li>
                    <li>
                      Hour Two: Developing a definition of vocation: The
                      importance of your "Why"
                    </li>
                  </ul>
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 2</div>
                <div className={Styles.subheading}>
                  <ul>
                    <li>
                      Hour One: Read "The Way It Is" and discuss Discover Your
                      Values
                    </li>
                    <li>Hour Two: Map your work experience</li>
                  </ul>
                </div>
                <div className={Styles.modal_paragraph}>
                  <div className={Styles.modal_subtitle}>Week 3</div>
                  <div className={Styles.subheading}>
                    <ul>
                      <li>
                        Hour One: Your passion story, how opposition makes us
                        stronger
                      </li>
                      <li>Hour Two: Create Personal life story maps</li>
                    </ul>
                  </div>
                </div>
                <div className={Styles.modal_paragraph}>
                  <div className={Styles.modal_subtitle}>Week 4</div>
                  <div className={Styles.subheading}>
                    <ul>
                      <li>Hour One: Healing Story, Pushback, Heart burst</li>
                      <li>Hour Two: Develop credo and share</li>
                    </ul>
                  </div>
                </div>
                <div className={Styles.modal_paragraph}>
                  <div className={Styles.modal_subtitle}>Week 5</div>
                  <div className={Styles.subheading}>
                    <ul>
                      <li>Hour One: Toxic Skills</li>
                      <li>
                        Hour Two: Vocational Preference Grid, compare and
                        contrast with your work experience
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={Styles.modal_paragraph}>
                  <div className={Styles.modal_subtitle}>Week 6</div>
                  <div className={Styles.subheading}>
                    <ul>
                      <li>
                        Hour One: Patinas, Create personal board of directors
                      </li>
                      <li>
                        Hour Two: Full integration of Vocational work: Tool Belt
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </TabOverview>
          <TabGuide>
            <div className={Styles.modal_title}>GUIDE</div>
            <div className={Styles.modal_body}>
              <img
                className={Styles.guide_img}
                src={GuideImg}
                alt="Deborah Loyd"
              />
              <div className={Styles.guide_name}>Deborah Loyd</div>
              <div className={Styles.guide_subheading}>
                <div>Pastor, Professor, Church Planter, Author</div>
                <div>Vocation Advance Guide</div>
              </div>
              <p>
                Deborah Loyd is a church planter, pastor, professor, and author
                of Your Vocational Credo. She planted The Bridge Church in
                Portland in 1998, along with two other pastors, as a safe space
                for those who did not fit in other contexts. She’s also the
                co-creator of Women’s Convergence, which supports women in
                Christian ministry. She also serves in a variety of roles,
                notably as a professor in Portland, as well as an advisor and
                coach to others seeking to find their unique voice through God’s
                Story.
              </p>
              <p>
                She has earned a Bachelor’s degree in Biblical Studies, a
                Master’s degree in Exegetical Theology and a Doctorate in
                Transformational Leadership. She is married to the love of her
                life, Ken, who plants churches for those who live outdoors.
              </p>
            </div>
          </TabGuide>
        </Col>
      </Row>
    </>
  );
};

export default CourseVocation;
